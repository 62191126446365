body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.grid-image .ant-image {
  height: 100% !important;
  width: 100% !important;
}
.ant-collapse-content-box {
  padding-top: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .liked-icon {
    animation: like-logo 0.7s ease-in-out;
  }
}

@keyframes like-logo {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.8);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

/**
    Custom style form phone number style
*/
input[type='tel' i] {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

input[type='tel' i]:focus {
  border-color: #4096ff;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}
.ant-layout-sider-trigger {
  background-color: #6c7cff !important;
}
